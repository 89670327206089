import { createApp } from 'vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import App from './App.vue'
import router from './router'
import store from './store'
import Mono from 'vue-mono'

import Toast from 'vue-toastification'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'

const app = createApp(App).use(store).use(router)

app.provide('$store', store).use(Toast).use(Mono, { publicKey: 'live_pk_wzix018juguu2ldidg3d' })

app.mixin({
  methods: {
    formatDate (dateString) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const currentDateTime = new Date(dateString)
      return currentDateTime.getDate() + ' ' + months[currentDateTime.getMonth()] + ' ' + currentDateTime.getFullYear()
    },
    formatDateTime (dateString) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const currentDateTime = dateString ? new Date(dateString) : null
      return currentDateTime ? currentDateTime.getDate() + ' ' + months[currentDateTime.getMonth()] + ' ' + currentDateTime.getFullYear() + ' ' + currentDateTime.toLocaleTimeString() : null
    },
    formatNumber (number) {
      const formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'NGN' })
      return formatter.format(number).substring(4)
    }
  }
})

app.mount('#app')
